// Theme
$theme-light-blue: #0071ce;
$theme-dark-blue: #002f87;
$theme-light-red: #f32735;
$theme-dark-red: #e10027;
$theme-light-green: #63be45;
$theme-dark-green: #2E8437;
$theme-extra-light-grey: #d9d9d6;
$theme-light-grey: #adafb0;
$theme-dark-grey: #636569;
$theme-extra-dark-grey: #19191a;
$theme-extra-dark-grey-alt: #1e1e20;
$theme-light-purple: #a747d7;
$theme-dark-purple: #671973;
$theme-light-orange: #ff9800;
$theme-dark-orange: #cb3f17;
$theme-light-slate: #a0b7c7;
$theme-dark-slate: #476f86;
$theme-light-teal: #30beaa;
$theme-dark-teal: #00818a;
$theme-light-coral: #ec3d6d;
$theme-dark-coral: #dd004a;
$theme-light-byzantine: #dc0094;
$theme-dark-byzantine: #7d0061;
$theme-yellow: #fbce39;
$theme-black: #222;
$theme-white: #fff;
$theme-true-black: #000;

// Dark Theme
$dark-theme-background: #262a2d;
$dark-theme-background-alt: #282e31;

// Other Colors
$link-visited: darken($theme-light-blue, 10%);
$link-hover: darken($theme-light-blue, 5%);
