@import '../_import';

.covid-19-banner {
	background: var(--theme-dark-red);
	color: var(--theme-white);
	@include default-padding-sides;
	padding-top: 1rem;
	padding-bottom: 1rem;

	a {
		color: var(--theme-white);
		font-size: 1.25rem;
		display: flex;
		flex-flow: row nowrap;
		align-content: center;
		text-decoration: none;

		.material-icons {
			padding-right: 0.25rem;
		}

		span {
			text-decoration: underline;
		}

		&:focus {
			outline: none;

			span {
				@include focusDarkBg;
			}
		}
	}

	@media only screen and (max-width: $menu-shrink) {
		padding: 0.25rem 0.5rem;

		a {
			font-size: 1rem;

			.material-icons {
				padding-right: 0.25rem;
				font-size: 1.25rem;
			}
		}
	}
}
