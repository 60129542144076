$default-font-family: interstate, overpass, system-ui, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Symbols", "AppleSymbols";
$base: 16px;
$default-line-height: 1.6;
$default-transition: all 0.2s ease-in-out;
$main-content-width: 110rem;
$main-content-padding-small: 1rem;
$main-content-padding: 2rem;
$main-content-padding-big: 4rem;

$grid-col-count: 6;
$grid-row-gap: $base;
$grid-col-gap: $grid-row-gap;

$inside-header-size: 12.5rem;
$inside-menu-item-size: 2.25rem;

$max-type-width: 95ch;

$font-body-weight: 500;
$font-bold-weight: 700;
$font-display-weight: 900;
