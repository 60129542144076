@import '../_import.scss';

.search-results {
	@include default-padding-sides;
	display: grid;
	grid-template-columns: repeat(2, 25rem);
	grid-template-rows: 1fr;
	grid-template-areas: 'stops places';
	grid-gap: 4rem;
	max-width: 94rem;
	margin: 0 auto 5rem auto;

	h2 {
		@include font-bold;
		margin: 1rem 0 0.5rem 0;
		padding: 0 0 0.25rem 0;
		color: var(--blue-text);
		border-bottom: 0.125rem solid var(--theme-light-grey);
		font-size: 2.5rem;
	}

	&.place-first {
		grid-template-areas: 'places stops';
	}

	.results {
		grid-area: stops;
	}

	.places {
		grid-area: places;

		> h2 {
			background-image: url('/content/image/powered-by-google.png');
			background-repeat: no-repeat;
			background-position: right 0.75rem;
			background-size: 9rem;

			@media only screen and (min-resolution: 1.5dppx) {
				background-image: url('/content/image/powered-by-google-2x.png');
			}
		}
	}

	@media only screen and (max-width: $homepage-shrink-search-results) and (min-width: $homepage-stack-search-results + 1px) {
		grid-gap: 2rem;
		grid-template-columns: repeat(2, 1fr);
	}

	@media only screen and (max-width: $homepage-stack-search-results) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, min-content);
		grid-template-areas: 'stops' 'places';
		grid-gap: 2rem;

		&.place-first {
			grid-template-areas: 'places' 'stops';
		}
	}
}
