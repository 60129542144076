@import '../_import.scss';

.search-result {
	padding: 0.75rem 0;
	background: var(--background);
	transition: background 0.2s ease-in-out;
	min-height: 8.75rem;
	display: flex;
	flex-flow: column nowrap;

	.display {
		flex: 0 0 auto;
	}

	.actions {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
	}

	&:hover {
		background: var(--background-alt);
	}

	+ .search-result {
		border-top: 0.0625rem solid var(--theme-light-grey);
	}

	.line-one, .line-two {
		overflow: hidden;
	}

	.line-one {
		font-size: 1.375rem;
		line-height: 1.22;
		color: var(--extra-dark-text);

		.word {
			float: left;
		}

		.word + .word {
			margin-left: 0.5rem;
		}

		span, b {
			@include font-bold;
		}

		b {
			background: var(--text-highlight);
		}
	}

	.line-two {
		@include font-bold;
		font-size: 1rem;
		color: var(--light-blue-text);
	}

	.secondary-actions {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;

		a {
			flex: 0 0 auto;

			+ a {
				margin-left: 0.5rem;
			}
		}
	}

	.primary-action {
		margin-top: 0.75rem;
	}
}
