@import '../_import.scss';

.fare {
	@include default-padding-sides;
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
	background: var(--dark-blue-background);

	.fare-content {
		max-width: $main-content-width;
		margin: 0 auto;
		display: grid;
		grid-template-rows: repeat(3, min-content);
		grid-template-columns: repeat(2, min-content);
		justify-items: center;
		grid-gap: 0 $main-content-padding;
	}

	h3, p {
		@include font-body;
		color: var(--light-text);
		grid-column: auto;
		max-width: 100%;
		width: 44rem;
	}

	h3 {
		grid-row: 1 / span 1;
		font-size: 3rem;
		line-height: 3.25rem;
		margin: 0 0 1.875rem 0;

		strong, b {
			@include font-bold;
			color: var(--white-text);
		}
	}

	p {
		font-size: 1.5rem;
		grid-row: 2 / span 1;
		margin: 0;
	}

	.link {
		grid-column: 1 / span 2;
		grid-row: 3 / span 1;
		margin: 2rem 0 0 0;
		justify-self: flex-start;
	}

	@media only screen and (max-width: $homepage-smaller-font) {

		h3 {
			font-size: 2.125rem;
		}

		p {
			font-size: 1.25rem;
			line-height: 1.68;
		}
	}

	@media only screen and (max-width: $homepage-stack-fares) {
		padding-top: 2rem;
		padding-bottom: 2rem;

		.fare-content {
			display: block;
		}

		h3 {
			margin-bottom: 0.25rem;
		}

		p + h3 {
			margin-top: 2.125rem;
		}
	}
}
