@import '../_import.scss';


.accessibility {

	@include default-padding-sides;
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
	background: var(--background-alt);

	.accessibility-content {
		max-width: $main-content-width;
		margin: 0 auto;

		h2, p {
			color: var(--dark-text);
		}

		h2 {
			margin-top: 0;
		}

		p {
			font-size: 1.125rem;
			line-height: 1.66667;

			&:last-of-type {
				margin-bottom: 0
			}
		}
	}
}
