@import '../_import.scss';

.search-results.geo-search-results {
	display: block;

	.results-list {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
		grid-gap: 0 1rem;

		@media only screen and (max-width: $homepage-shrink-nearby) {
			grid-template-columns: 1fr;
		}
	}

	h2 {
		margin-bottom: 1rem;
	}

	.nearby-stop {
		padding: 0.75rem 0;
		border-bottom: 0.0625rem solid var(--theme-light-grey);

		+ .nearby-stop {
		}
	}

	.stop-name {
		@include font-bold;
		font-size: 1.375rem;
		line-height: 1.22;
		color: var(--extra-dark-text);
		margin: 0;
	}

	.distance {
		@include font-bold;
		font-size: 1rem;
		color: var(--light-blue-text);
		margin: 0;
	}

	.primary-actions {
		margin-top: 0.75rem;
	}

	ul.served-by-list {
		display: flex;
		flex-flow: row wrap;
		margin: 0.5rem 0 0.75rem 0;
		padding: 0;
		list-style: none;

		li.served-by {
			@include font-display;
			padding: 0;
			margin: 0.25rem 0 0 0;
			width: 2.5rem;
			height: 2.5rem;
			font-size: 1.25rem;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			flex: 0 0 auto;

			+ .served-by {
				margin-left: 0.25rem;
			}
		}
	}

	.accuracy {
		@include font-body;
		margin: 1rem 0 0 0;
		font-size: 1.5rem;
		color: var(--dark-text-alt2);
		text-transform: uppercase;
	}
}
