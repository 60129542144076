@import '../_import.scss';

.favs {
	&:empty {
		display: none;

		+ .pulse {
			background: var(--background);
		}
	}

	@include font-body;
	@include default-padding-sides;
	padding-bottom: 5rem;
	margin: 0 auto;
	max-width: $main-content-width;

	.favorites-heading {
		display: flex;
		flex-flow: row nowrap;
		max-width: 46rem;

		span {
			padding-top: 2rem;
			flex: 1 1 auto;
		}

		svg {
			width: 8rem;
			height: 8rem;
			flex: 0 0 auto;
		}

		@media only screen and (max-width: $homepage-smaller-font) {
			span {
				padding-top: 0.5rem;
				flex: 0 0 auto;
			}

			svg {
				margin-left: 4rem;
				width: 4.5rem;
				height: 4.5rem;
			}
		}

		@media only screen and (max-width: $homepage-small-favicon) {
			svg {
				margin-left: 0;
			}
		}
	}

	.fav-list {
		display: grid;
		grid-template-columns: repeat(auto-fill, 25.25rem);
		grid-template-rows: auto;
		grid-gap: 3.125rem;

		@media only screen and (max-width: $homepage-shrink-cards) {
			grid-template-columns: 1fr;
			grid-gap: 1.5rem;
		}
	}

	.fav {
		min-height: 16.875rem;
		width: 100%;
		max-width: 25.25rem;
		padding: 1.375rem 1.625rem;
		box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.25);
		display: flex;
		flex-flow: column nowrap;

		h3, h4 {
			@include font-bold;
			font-size: 1.5625rem;
			line-height: 1.2;
			margin: 0;
		}

		h3 {
			color: var(--extra-dark-text);
		}

		h4 {
			color: var(--light-blue-text);
			flex: 1 1 auto;
		}

		.button {
			align-self: flex-start;
		}

		@media only screen and (max-width: $homepage-shrink-cards) {
			min-height: 10rem;
			max-width: 100vw;

			h3, h4 {
				font-size: 1.375rem;
				line-height: 1.22;
			}
		}

		/*@media (prefers-color-scheme: dark) {
			background: var(--background-alt);
		}*/
	}

	@media only screen and (max-width: $homepage-shrink-cards) {
		padding-bottom: 2rem;
	}
}
