@import '../_import.scss';

.apps {
	@include font-body;
	@include default-padding-sides;
	padding-top: 5.25rem;
	font-size: 3.75rem;
	line-height: 1;
	margin: 0 auto 2.8125rem auto;
	max-width: $main-content-width;

	.apps-wrap {
		display: grid;
		grid-template-columns: repeat(3, min-content);
		grid-template-rows: repeat(2, min-content);
		grid-template-areas: 'logo info downloads' 'description description description';
		grid-gap: 2rem 3rem;
		grid-auto-flow: column;
		width: 87rem;
		max-width: 100%;

		img {
			grid-area: logo;
			width: 8.4375rem;
			height: 8.4375rem;
			border-radius: 0.5rem;
		}

		.info {
			grid-area: info;

			h3, .author {
				white-space: nowrap;
			}

			h3 {
				@include font-bold;
				font-size: 2.8125rem;
				color: var(--blue-text);
				margin: 2rem 0 0 0;
			}

			.author {
				@include font-body;
				line-height: 1.33;
				font-size: 1.875rem;
				color: var(--extra-dark-text);
				margin: 0;
			}
		}


		.downloads {
			grid-area: downloads;
			display: flex;
			flex-flow: row nowrap;

			.download {
				margin: 2rem 1rem 2rem;

				> svg {
					width: 12.5rem;
					height: 3.75rem;
				}
			}
		}
	}

	@media only screen and (max-width: $homepage-stack-apps) {
		padding-top: 2rem;
		padding-bottom: 2rem;
		margin-bottom: 0;

		h2 {
			margin-top: 0;
		}

		.apps-wrap {
			grid-template-columns: min-content 1fr;
			grid-template-rows: repeat(3, min-content);
			grid-gap: 0.5rem 1.25rem;
			grid-template-areas: 'logo info' 'downloads downloads' 'description description';

			img {
				width: 4.4375rem;
				height: 4.4375rem;
			}

			.info {
				h3, author {
					line-height: 1.25;
					margin: 0;
				}

				h3 {
					font-size: 1.875rem;
				}

				.author {
					font-size: 1.25rem;
				}
			}

			.downloads {
				margin-top: 0.25rem;

				.download {
					margin-top: 0;
					margin-right: 1.5rem;

					> svg {
						width: 9.375rem;
						height: 2.8125rem;
					}
				}
			}

			.description {
				font-size: 1.125rem;
				line-height: 1.66667;
				margin-bottom: 0
			}

			+ .apps-wrap {
				margin-top: 2rem;
			}
		}

		.all-apps {
			margin: 1.5rem 0 0.5rem 0;
		}
	}

	@media only screen and (max-width: $homepage-tiny-apps) {
		.apps-wrap {
			.downloads {

				.download {

					> svg {
						width: 8rem;
						height: 2.4rem;
						margin-right: 0.5rem;
					}
				}
			}
		}
	}

	.description {
		grid-area: description;
		font-size: 1.4375rem;
		line-height: 2.625rem;
		color: var(--extra-dark-text);
		margin: 0 0 1.5rem 0;
	}

	.all-apps {
		margin-top: 2rem;
	}
}
