@import '../_import.scss';

.have-a-question {
	background: var(--theme-light-blue);
	width: 100%;

	p {
		@include font-body;
		font-size: 3.75rem;
		line-height: 3;
		color: var(--light-text);
		text-align: center;
		margin: 0;
		padding: 0;
		max-width: unset;

		strong {
			@include font-display;
			margin-left: 5.875rem;
			color: var(--light-text)!important;
		}

		@media(max-width: $have-a-question-shrink-1) {
			strong {
				margin-left: 0.5rem;
			}
		}

		@media(max-width: $have-a-question-shrink-2) {
			font-size: 2.5rem;
		}

		@media(max-width: $have-a-question-shrink-3) {
			font-size: 1.75rem;
		}

		@media(max-width: $have-a-question-shrink-4) {
			font-size: 1.25rem;

			strong {
				margin-left: 0;
			}
		}

		@media(max-width: $have-a-question-shrink-5) {
			line-height: 1.5;

			strong {
				display: block;
			}
		}
	}
}
