@import '../_import.scss';

.hero-wrap {
	background: var(--theme-light-blue);
	position: relative;
	background-position: center;
	background-size: cover;
	padding-bottom: 8.9%;
	overflow: hidden;
	max-width: 100%;

	h1, h2, p, strong {
		color: var(--theme-white);
	}

	h1 {
		@include font-body;
		font-size: 6.25rem;
		line-height: 1.2;
		margin: 0 0 2.25rem 0;
		padding: 0;

		strong {
			@include font-bold;
			border-bottom: 0.8125rem solid var(--theme-dark-blue);
		}

		@media only screen and (max-width: $homepage-medium-hero) {
			font-size: 4rem;

			strong {
				border-bottom-width: 0.625rem;
			}
		}

		@media only screen and (max-width: $homepage-small-hero) {
			font-size: 1.5rem;
			margin-bottom: 1.25rem;

			strong {
				border-bottom-width: 0.25rem;
			}
		}
	}

	p {
		@include font-body;
		font-size: 2rem;
		line-height: 1.31;
		margin: 0 0 2.25rem 0;

		strong {
			@include font-bold;
		}

		@media only screen and (max-width: $homepage-medium-hero) {
			font-size: 1.5rem;
		}

		@media only screen and (max-width: $homepage-small-hero) {
			font-size: 1.125rem;
			margin-bottom: 1.5rem;
		}
	}

	.location-search-bar {
		max-width: 90rem;
		margin: 0 auto;
		padding: 8.125rem 2rem 6rem 2rem;

		@media only screen and (max-width: $homepage-medium-hero) {
			padding-top: 3rem;
			padding-bottom: 2rem;
		}

		@media only screen and (max-width: $homepage-small-hero) {
			padding-top: 2rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}

		@media only screen and (max-height: $homepage-short-browser) and (min-width: $homepage-medium-hero + 1px) {
			padding-top: 3rem;
			padding-bottom: 2rem;
		}
	}

	.location-input {
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-template-rows: 1fr;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		max-width: 85rem;
		margin: 0;

		input[type="search"] {
			@include font-body;
			display: block;
			font-size: 1.75rem;
			line-height: 2;
			border: none;
			padding: 0.25rem 2rem 0.1875rem 2rem;
			border-radius: 2.03125rem;
			width: 100%;
			color: var(--dark-text);
			background: var(--background);
			-webkit-appearance: none;

			&:focus {
				@include focusDouble;
			}

			@media only screen and (max-width: $homepage-shrink-hero-search) {
				font-size: 1.25rem;
				line-height: 1.5rem;
				padding: 0.3125rem 1.5rem;
				border-radius: 2.125rem / 2;
			}

			@media only screen and (max-width: $homepage-small-hero) {
				font-size: 0.875rem;
				padding: 0.3125rem 1rem;
			}

			&:disabled {
				cursor: not-allowed;
				background: var(--theme-light-grey);
			}
		}

		.location-button {
			margin-left: 2.5rem;

			@media only screen and (max-width: $homepage-shrink-hero-search) {
				margin-left: 1rem;

				&.large {
					font-size: 1rem;
					height: 2.125rem;
					border-radius: 2.125rem / 2;
					padding: 0 0.75rem 0 0.5rem;

					.material-icons {
						font-size: 1.75rem;
						margin-right: 0.125rem;
					}
				}
			}
		}
	}

	.swoosh {
		position: absolute;
		bottom: -0.125rem;
		left: -0.5rem;
		right: -0.5rem;
		width: 110%; // fixes safari issue

		.whitespace {
			fill: var(--background);
		}

		.stroke {
			stroke-linecap: square;
			fill: none;
			stroke: var(--theme-dark-blue);
			stroke-width: 8;

			@media only screen and (max-width: $homepage-swoosh-stroke-medium) {
				stroke-width: 16;
			}

			@media only screen and (max-width: $homepage-swoosh-stroke-large) {
				stroke-width: 24;
			}

			@media only screen and (max-width: $homepage-swoosh-stroke-mobile) {
				stroke-width: 30;
			}
		}
	}
}
