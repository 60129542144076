@import '../_import.scss';

.pulse {
	@include default-padding-sides;
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
	background: var(--background-alt);

	.pulse-wrap {
		max-width: $main-content-width;
		margin: 0 auto;
	}

	h2 {
		margin: 0 0 1rem 0;
	}

	h3 {
		@include font-bold;
		font-size: 1.875rem;
		line-height: 1.5;
		margin: 0 0 1.875rem 0;
	}

	p {
		@include font-body;
		font-size: 1.4375rem;
		margin-bottom: 1.875rem;
	}

	@media only screen and (max-width: $homepage-smaller-font) {
		padding-top: 1.875rem;
		padding-bottom: 1.875rem;

		h3, p, li a {
			font-size: 1.25rem;
		}
	}
}

.pulse-article-preview {
	margin-bottom: 3.375rem;

	@media only screen and (max-width: $homepage-smaller-font) {
		margin-bottom: 2.75rem;
	}
}

ul.other-headlines {

	&, li {
		margin: 0;
		padding: 0;
		list-style: none;
		max-width: unset;
	}

	a {
		@include font-body;
		color: var(--link-text);
		font-size: 1.875rem;
		line-height: 1.33;

		@media only screen and (max-width: $homepage-smaller-font) {
			font-size: 1.25rem;
			line-height: 1.5;
		}
	}

	li + li {
		margin-top: 2rem;
	}
}
